import React, { Component } from "react";
import TopNavbar from "./TopNavbar";
import Footer from "./Footer";
import { Switch, Route } from "react-router-dom";
import OnRouteChange from "./onRouteChange";
import HomePage from "./homePage/HomePage";
import ContactPage from "./contactPage/ContactPage";
import ServiceProfilePage from "./serviceProfilePage/ServiceProfilePage";
import Blogs from "./blogs/Blogs";

import $ from "jquery";
import blog1 from "./blogs/blog1";
import Blog2 from "./blogs/blog2";
import { ScrollProvider } from "./ScrollContext";
import AboutPage from "./AboutPage/AboutPage";
import ServicePage from "./ServicePage/ServicePage";
import Blog3 from "./blogs/blog3";
import Blog4 from "./blogs/blog4";
import Blog5 from "./blogs/blog5";
import Blog6 from "./blogs/blog6";
import Blog7 from "./blogs/blog7";
import Blog8 from "./blogs/blog8";
import Blog9 from "./blogs/blog9";
import Blog10 from "./blogs/blog10";
import Blog11 from "./blogs/blog11";

import demo from "./blogs/demo";

class App extends Component {
  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }
  handleLoad() {
    $(".preloader").fadeOut();
    $(".animated").appear(function () {
      var elem = $(this);
      var animation = elem.data("animation");
      if (!elem.hasClass("visible")) {
        var animationDelay = elem.data("animation-delay");
        if (animationDelay) {
          setTimeout(function () {
            elem.addClass(animation + " visible");
          }, animationDelay);
        } else {
          elem.addClass(animation + " visible");
        }
      }
    });
  }
  render() {
    return (
      <div className="App">
        <div className="preloader">
          <div>
            <svg
              width="150px"
              height="179px"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="d-spinner d-spinner__four"
                d="M144.421372,121.923755 C143.963266,123.384111 143.471366,124.821563 142.945674,126.236112 C138.856723,137.238783 133.098899,146.60351 125.672029,154.330576 C118.245158,162.057643 109.358082,167.978838 99.0105324,172.094341 C89.2149248,175.990321 78.4098994,178.04219 66.5951642,178.25 L0,178.25 L144.421372,121.923755 L144.421372,121.923755 Z"
              />
              <path
                className="d-spinner d-spinner__three"
                d="M149.033408,92.6053108 C148.756405,103.232477 147.219069,113.005232 144.421372,121.923755 L0,178.25 L139.531816,44.0158418 C140.776016,46.5834381 141.913968,49.2553065 142.945674,52.0314515 C146.681818,62.0847774 148.711047,73.2598899 149.033408,85.5570717 L149.033408,92.6053108 L149.033408,92.6053108 Z"
              />
              <path
                className="d-spinner d-spinner__two"
                d="M80.3248924,1.15770478 C86.9155266,2.16812827 93.1440524,3.83996395 99.0105324,6.17322306 C109.358082,10.2887257 118.245158,16.2099212 125.672029,23.9369874 C131.224984,29.7143944 135.844889,36.4073068 139.531816,44.0158418 L0,178.25 L80.3248924,1.15770478 L80.3248924,1.15770478 Z"
              />
              <path
                className="d-spinner d-spinner__one"
                d="M32.2942065,0 L64.5884131,0 C70.0451992,0 75.290683,0.385899921 80.3248924,1.15770478 L0,178.25 L0,0 L32.2942065,0 L32.2942065,0 Z"
              />
            </svg>
          </div>
        </div>
        <ScrollProvider>
          <TopNavbar />
          <OnRouteChange>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/service/:id" component={ServiceProfilePage} />
              <Route
                exact
                path="/electromechanical-equipment-installation-and-maintenance-services-in-dubai"
                component={blog1}
              ></Route>
              <Route exact path="/blogs" component={Blogs} />
              <Route
                exact
                path="/plumbing-and-sanitary-contracting-in-dubai-uae"
                component={Blog2}
              ></Route>
              
              <Route exact path="/about" component={AboutPage}></Route>
              <Route exact path="/service" component={ServicePage}></Route>
              <Route exact path="/demo" component={demo}></Route>
              <Route
                exact
                path="/best-solar-installation-in-dubai-uae"
                component={Blog3}
              ></Route>
              <Route
                exact
                path="/mep-maintenance-company-in-uae"
                component={Blog4}
              ></Route>
              <Route
                exact
                path="/facade-cleaning-services-in-dubai"
                component={Blog5}
              ></Route>
              <Route
                exact
                path="/lifting-equipment-in-dubai"
                component={Blog6}
              ></Route>
              <Route
                exact
                path="/generator-maintenance-and-spare-parts-solutions-in-UAE-dubai"
                component={Blog7}
              ></Route>
              <Route
                exact
                path="/best-garbage-chute-maintenance-service-in-dubai"
                component={Blog8}
              ></Route>
              <Route
                exact
                path="/cctv-maintenance-services-in-uae"
                component={Blog9}
              ></Route>
              <Route
                exact
                path="/ac-installation-services-in-uae"
                component={Blog10}
              ></Route>
              <Route
                exact
                path="/water-tank-cleaning-solution-in-dubai"
                component={Blog11}
              ></Route>
            </Switch>
          </OnRouteChange>
          <Footer />
        </ScrollProvider>
      </div>
    );
  }
}

export default App;
